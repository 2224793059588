import React from 'react';
import { resizePuzzle } from './index';

export const CELL_SHADING = {
  NONE:     0,
  SHADED:   1,
  UNSHADED: 2
};

class Cell extends React.Component {

  valueString() {
    const { value } = this.props;

    if (value.given) {
      return value.given_value.split("").map((digit, i) => <span key={i}> {digit} </span>);
    } else if (value.shading === CELL_SHADING.UNSHADED) {
      return "•";
    } else {
      return "";
    }
  }

  render() {
    const { value } = this.props;
    let className = "cell";

    if (value.given) {
      className += " given";
      className += " digit" + value.given_value.length;
    } else if (value.shading === CELL_SHADING.NONE) {
      className += " unknown";
    } else if (value.shading === CELL_SHADING.SHADED) {
      className += " shaded";
    } else if (value.shading === CELL_SHADING.UNSHADED) {
      className += " unshaded";
    }

    return <div
             className={className}
             data-row={value.row}
             data-col={value.col}
           > {this.valueString()} </div>;
  }
}

const EVENT = {
  OTHER: 0,
  START: 1,
  END: 2
};

class Tapa extends React.Component {
  constructor(props) {
    super(props);
    this.drawing = { active: false, color: "" };
    this.state = {
      cells: this.initCells(props.puzzle)
    };

    /* Capture mouse up events at the window level to stop drawing. */
    window.addEventListener("mouseup", () => {
      this.stopDrawing();
    });

  }

  /* Disable all scrolling when a puzzle is rendered. */
  componentDidMount() {
    document.body.style.overflowY = "hidden";
    resizePuzzle();
  }

  componentWillUnmount() {
    document.body.style.overflowY = "visible";
  }

  initCells(puzzle) {
    let cells = [];

    for (let row = 0; row < puzzle.height; row++) {
      cells.push([]);
      for (let col = 0; col < puzzle.width; col++) {
        cells[row][col] = {
          row: row,
          col: col,
          given: false,
          given_value: undefined,
          shading: CELL_SHADING.NONE
        };
        if (puzzle.givens[row][col]) {
          cells[row][col].given = true;
          cells[row][col].given_value = puzzle.givens[row][col];
        }
      }
    }

    return cells;
  }

  /* Start a click or a click-and-drag event in a particular cell. */
  startDrawing(row, col, modified) {
    const cells = this.state.cells;

    this.drawing.shading = undefined;
    this.drawing.start_row = row;
    this.drawing.start_col = col;
    this.drawing.active = true;

    /* When we first get a click in a cell we modify the cell contents
     * as follows:
     *
     * Unmodified event: NONE -> SHADED, SHADED -> UNSHADED, UNSHADED -> NONE
     *
     * Modified event: NONE -> UNSHADED, UNSHADED -> SHADED, SHADED -> NONE
     */
    if (! modified) {
      if (cells[row][col].shading === CELL_SHADING.NONE) {
        this.drawing.shading = CELL_SHADING.SHADED;
      } else if (cells[row][col].shading === CELL_SHADING.SHADED) {
        this.drawing.shading = CELL_SHADING.UNSHADED;
      } else {
        this.drawing.shading = CELL_SHADING.NONE;
      }
    } else {
      if (cells[row][col].shading === CELL_SHADING.NONE) {
        this.drawing.shading = CELL_SHADING.UNSHADED;
      } else if (cells[row][col].shading === CELL_SHADING.UNSHADED) {
        this.drawing.shading = CELL_SHADING.SHADED;
      } else {
        this.drawing.shading = CELL_SHADING.NONE;
      }
    }
    cells[row][col].shading = this.drawing.shading;
    this.setState({ cells: cells });
  }


  /* Extend a click-and-drag event into a cell. */
  keepDrawing(row, col) {
    const cells = this.state.cells;
    const {start_row, start_col} = this.drawing;

    /* Ignore events in the starting cell. */
    if (row === start_row && col === start_col)
      return;

    /* Do nothing if drawing color is already set. */
    if (cells[row][col].shading === this.drawing.shading)
      return;

    cells[row][col].shading = this.drawing.shading;

    this.setState({
      cells: cells
    });
  }

  /* Terminate a click or click-and-drag event in a cell. */
  stopDrawing(row, col) {
    this.drawing.active = false;
  }

  /* Process any of the interactive events that can change a cell.
   *
   * This common function is intended to handle all cases whether
   * a mouse event or a touch event and whether this is the first
   * event in a series (such as MouseDown or TouchStart) or a
   * subsequent event (such as MouseMove or TouchMove).
   *
   * 'x' and 'y' are mouse/touch-device coordinates (not cell coordinates)
   *
   * 'modified' is a Boolean indicating things like right click
   *
   * Optional 'start_end' indicates whether this is known to be
   * the first (EVENT.START) or last (EVENT.END) event of a series.
   */
  processCellEvent(x, y, modified, start_end) {

    /* Ignore any motion events not part of a click-and-drag. */
    if (! (start_end === EVENT.START || this.drawing.active))
      return;

    const cells = this.state.cells;
    const cell = document.elementFromPoint(x, y);

    /* Ignore any non-cell element. */
    if (! cell.classList.contains("cell"))
      return;

    const row = cell.dataset.row;
    const col = cell.dataset.col;

    /* Also, do nothing on any given cell. */
    if (cells[row][col].given)
      return;

    /* When this is the first of several events, we set the drawing
     * state based on the state of this cell. */
    if (start_end === EVENT.START) {
      this.startDrawing(row, col, modified);
    } else if (start_end === EVENT.END) {
      this.stopDrawing(row, col);
    } else {
      this.keepDrawing(row, col);
    }
  }

  handleMouseDown = (event) => {
    this.processCellEvent(event.clientX, event.clientY,
                          event.button !== 0,
                          EVENT.START);
  };

  handleMouseMove = (event) => {
    this.processCellEvent(event.clientX, event.clientY, false);
  };

  handleMouseUp = (event) => {
    this.processCellEvent(event.clientX, event.clientY, false, EVENT.END);
  };

  handleTouchStart = (event) => {
    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;
    this.processCellEvent(x, y, false, EVENT.START)
  };

  handleTouchMove = (event) => {
    const last = event.touches.length - 1;
    this.processCellEvent(event.touches[last].clientX,
                          event.touches[last].clientY,
                          false);
  };

  handleTouchEnd = (event) => {
    const last = event.changedTouches.length - 1;
    this.processCellEvent(event.changedTouches[last].clientX,
                          event.changedTouches[last].clientY,
                          false,
                          EVENT.END);
    event.preventDefault();
  };

  /* We never want the browser's context menu interfering with our
   * right-click and touch-and-hold events. */
  handleContextMenu = (event) => {
    event.preventDefault();
  };

  renderCells(cells) {
    return cells.map((cells_row, row) => {
      return cells_row.map((cell, col) => {
        return (
          <Cell
            key={row+","+col}
            value={cell}/>
        );
      });
    });
  }

  render() {
    return (
      <div id="puzzle-body">
        <div id="puzzle-container">
          <div id="puzzle"
               className="tapa"
               onMouseDown={this.handleMouseDown}
               onMouseMove={this.handleMouseMove}
               onMouseUp={this.handleMouseUp}
               onTouchStart={this.handleTouchStart}
               onTouchMove={this.handleTouchMove}
               onTouchEnd={this.handleTouchEnd}
               onContextMenu={this.handleContextMenu}
               style={{
                 gridTemplateColumns: "repeat(" + this.props.puzzle.width + ",1fr)",
                 gridTemplateRows: "repeat(" + this.props.puzzle.height + ",1fr)",
                 fontSize: 40 / this.props.puzzle.width + "vmin"
               }}
          >
            {this.renderCells(this.state.cells)}
          </div>
        </div>
        <p>
          Rules: Shade some cells so that for each numbered cells the
          numbers describe the lengths of connected shaded cells in the
          8 cells surrounding the numbered cells. All shaded cells must be
          (4-way) connected in a single group, and no 2x2 square of cells
          can be entirely shaded.
        </p>
        <p>
          Controls: Click or tap (with optional drag) to toggle cells.
          Cells will toggle from empty to shaded, then to unshaded (marked
          with a dot), then back to empty again.
        </p>
      </div>
    );
  }
}

export default Tapa;

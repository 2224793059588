import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Link} from "react-router-dom"
import * as serviceWorker from './serviceWorker';

import './index.css';
import './puzzless.css';
import Tapa from './Tapa';
import StarBattle from './StarBattle';

const PuzzleList = () => (
  <div className="puzzle-list">
    <Link to="/puzzles/04">
      <div className="featured">
        <div className="preview">
          <img src="/star-battle-2019-01-24-preview.png" alt="Star Battle puzzle"/>
        </div>
        <div className="description">
          <span className="genre">Star Battle</span>
          <span className="theme">Theme: 10x10 Sample</span>
          <span className="date">January 24, 2019</span>
          <span className="author">By Carl Worth</span>
        </div>
      </div>
    </Link>
    <Link to="/puzzles/03">
      <div className="featured">
        <div className="preview">
          <img src="/star-battle-2019-01-17-preview.png" alt="Star Battle puzzle"/>
        </div>
        <div className="description">
          <span className="genre">Star Battle</span>
          <span className="theme">Theme: 5x5 Sample</span>
          <span className="date">January 17, 2019</span>
          <span className="author">By Carl Worth</span>
        </div>
      </div>
    </Link>
    <Link to="/puzzles/02">
      <div className="featured">
        <div className="preview">
          <img src="/tapa-2019-01-15-preview.png" alt="Tapa puzzle"/>
        </div>
        <div className="description">
          <span className="genre">Tapa</span>
          <span className="theme">Theme: 10x10 Sample</span>
          <span className="date">January 15, 2019</span>
          <span className="author">By Carl Worth</span>
        </div>
      </div>
    </Link>
    <Link to="/puzzles/01">
      <div className="featured">
        <div className="preview">
          <img src="/tapa-2019-01-09-preview.png" alt="Tapa puzzle"/>
        </div>
        <div className="description">
          <span className="genre">Tapa</span>
          <span className="theme">Theme: 6x6 Sample</span>
          <span className="date">January 9, 2019</span>
          <span className="author">By Carl Worth</span>
        </div>
      </div>
    </Link>
  </div>
);

const Puzzle01 = () => (
  <Tapa puzzle={{
    width: 6,
    height: 6,
    givens:  [
      [null, null, null, null, null, null],
      [null,  "5",  "3", null,  "5", null],
      [null, null, null, null,  "5", null],
      [null, "23", null, null, null, null],
      [null,  "7", null,  "5",  "3", null],
      [null, null, null, null, null, null],
    ]
  }}/>
);

const Puzzle02 = () => (
  <Tapa puzzle={{
    width: 10,
    height: 10,
    givens:  [
      [null, null,  "4", null, "13", null, null, null, null,  "3"],
      [ "2", null, null, null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null,  "1", null, null],
      [ "3", null, null, null,  "5", null, "13", null, null, null],
      [null, null,  "3", null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null,  "7", null, null],
      [null, null, null,  "3", null, "33", null, null, null,  "2"],
      [null, null,"112", null, null, null, null, null, null, null],
      [null, null, null, null, null, null, null, null, null,  "1"],
      [ "2", null, null, null, null, "22", null,  "4", null, null],
    ]
  }}/>
);

const Puzzle03 = () => (
  <StarBattle puzzle={{
    width: 5,
    height: 5,
    stars: 1,
    regions: [
      "ABBBC",
      "ABBDC",
      "DDDDC",
      "DEEEC",
      "EEEEE"
    ]
  }}/>
);

const Puzzle04 = () => (
  <StarBattle puzzle={{
    width: 10,
    height: 10,
    stars: 2,
    regions: [
      "AAAAAABBCC",
      "AADDAABBBC",
      "ADDDDACCCC",
      "ADEEDACFFC",
      "GHECCCCCFC",
      "GHEEEEECFC",
      "GHHHIIIIFF",
      "GGGHIJJJJJ",
      "JJHHIIIIJJ",
      "JJJJJJJJJJ"
    ]
  }}/>
);

class Puzzless extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div className="Puzzless">
          <Link className="Puzzless-header" to="/">
            <h1>Puzzless: Solve More</h1>
          </Link>

          <Route path="/" exact component={PuzzleList} />
          <Route path="/puzzles/01" component={Puzzle01} />
          <Route path="/puzzles/02" component={Puzzle02} />
          <Route path="/puzzles/03" component={Puzzle03} />
          <Route path="/puzzles/04" component={Puzzle04} />
        </div>
      </BrowserRouter>
    );
  }
}

/* Time to wait (in milliseconds) after a resize even to see if
 * another resize event comes in before recomputing the layout). */
const RESIZE_DEBOUNCE_TIME_MS = 10;

/* Resize a puzzle to as large as possible within its container.
 */
export function resizePuzzle() {
  const container = document.getElementById('puzzle-container');
  const puzzle = document.getElementById('puzzle');

  /* If there is no puzzle on this page there is nothing to do. */
  if (! puzzle)
    return;

  const container_rect = container.getBoundingClientRect();

  var final_size;

  if (container_rect.width < container_rect.height) {
    final_size = container_rect.width;
  } else {
    final_size = container_rect.height;
  }

  /* Center puzzle. */
  puzzle.style.left = (container_rect.width - final_size) / 2 + "px";
  puzzle.style.width = final_size + "px";
  puzzle.style.height = final_size + "px";
}

/* Also, whenever a resize event occurs, cancel any
 * previously-scheduled layout and schedule a new layout after a short
 * timeout. This way, we aren't doing a crazy number of layouts during
 * a stream of resize events, but only when the stream slows down.
*/
var timeout = false;

window.addEventListener('resize', function() {
  clearTimeout(timeout);
  timeout = setTimeout(resizePuzzle, RESIZE_DEBOUNCE_TIME_MS);
});

ReactDOM.render(<Puzzless />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
